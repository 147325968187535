<template>
  <v-container

    id="crud"
    fluid
    tag="section"
    class="px-6 mx-0"
  >
    <div v-if="$store.state.carrito.data.length == 0">
      <div
        style="height: 320px"
        class="carritoMsg__div px-2 d-flex flex-column justify-center align-items-center"
      >
        <div style="position: relative; display: inline-block">
          <v-icon
            color="text-center"
            style="font-size: 5rem; top: 0; left: 45%; pointer-events: none"
            >mdi-cart-outline</v-icon
          >

          <v-btn
            class="text-center primary"
            dark
            elevation="0"
            fab
            icon
            x-small
            style="position: absolute; top: 0; left: 47%; pointer-events: none"
          >
            <span style="font-size: 14px; padding: 5px">0</span>
          </v-btn>
        </div>
        <br />
        <p
          style="line-height: 1; font-weight: 500"
          class="display-1 text-center"
        >
          Tu carrito está vacio
        </p>
        <small style="line-height: 1; color: #999" class="text-center">
          Actualmente no hay productos en su carrito.<br />Puede seguir
          comprando y agregar los productos deseados.  <br><br><br>
          <v-btn
                width="150"
                color="success"
                class="text-center capitalize-first custom-btn"
                @click="verProductos"
              >
                Ver productos
              </v-btn>
        </small>


      </div>
    </div>
    <div v-else>
      <v-row class="my-2 px-0 mx-0">
        <v-col cols="auto" md="12" class="px-0 mx-0">
          <v-alert
            outlined
            type="info"
            colored-border
            color="info"
            elevation="2"
            class="mb-0"
          >
            <p class="info--text py-0 my-0" style="font-weight: 400">
              Los métodos de pago varían según los productos en tu carrito.
              Selecciona una opción para cada categoría. Si eliges 'Cheque Banco
              Nación', se te solicitarán los datos al presionar 'Confirmar’
            </p>
          </v-alert>
        </v-col>

        <v-col
          cols="auto"
          md="12"
          class="px-0 py-0 mx-0"
          v-if="$store.state.carrito.total > 9999999"
        >
          <v-alert
            outlined
            type="info"
            colored-border
            color="info"
            elevation="2"
            class="my-0 mb-4"
          >
            <p class="info--text py-0 my-0" style="font-weight: 400">
              Para montos superiores a $9.999.999 el método de pago 'Boleta Banco Nación' no estará disponible.
            </p>
          </v-alert>
        </v-col>

        <v-col cols="auto" md="4" class="">
          <v-badge
            v-if="$store.state.carrito.cantItem > 0"
            color="primary"
            overlap
            bordered
          >
            <template v-slot:badge>
              <span>{{ $store.state.carrito.cantItem }}</span>
            </template>

            <v-icon class="icon-25">mdi-cart-outline</v-icon>
          </v-badge>
          <v-badge v-else overlap bordered>
            <template v-slot:badge>
              <span>0</span>
            </template>

            <v-icon style="color: #000; font-size: 30px"
              >mdi-cart-outline</v-icon
            >
          </v-badge>
          <span class="ml-3 text-h6" style="">Carrito</span>
        </v-col>
      </v-row>





      <Loading  v-if="isLoading" :isLoading="isLoading" :loadingMsg="loadingMsg" />

      <v-row class="my-0 px-0 mx-0" v-else>
        <v-col cols="auto" md="9" class="ma-0 pa-0 pr-2">
          <v-row v-if="this.$store.state.carrito.cliente.CardCode" class="elevation-3 mx-1 pt-1 pb-0 mt-0 mb-1 rounded-lg">
              <v-col class="col-md-12 py-0 mt-3   text-left"  >
                  <span style="font-weight: 600;">Nombre: </span>
                  <span>{{  this.$store.state.carrito.cliente.CardName}}</span>
              </v-col>
              <v-col class="col-md-6 py-0 mt-3   text-left">
                  <span style="font-weight: 600;">Documento: </span>
                  <span>{{  this.$store.state.carrito.cliente.FederalTaxID}}</span>
              </v-col>
          </v-row>
          <div
            v-for="(tipo, index) in carritoTiposElementos"
            :key="tipo.TipoElementosId"
            class="mb-4"
          >
            <v-card class="bg-secondary">
              <v-card-title style="border-bottom: 1px solid #ddd">
                <v-row class="py-0 px-0" style="height: 68px">
                  <v-col
                    cols="auto"
                    style="width: 5%"
                    class="px-0 mx-0 py-0 mt-3"
                  >
                    <v-btn
                      x-small
                      @click="toggleCard(index)"
                      class="btn-panel-mas-menos ml-1"
                    >
                      <v-icon style="font-size: 1.2rem">{{
                        cardStates[index] ? "mdi-minus" : "mdi-plus"
                      }}</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="auto"
                    style="width: 50%"
                    class="px-0 mx-0 font-weight-bold py-0 mt-3 text-left"
                    >{{ tipo.TipoElementosDesc }}</v-col
                  >
                  <v-col
                    cols="auto"
                    md="1"
                    class="px-0 mx-0 font-weight-bold py-0 mt-4"
                  >
                    <div
                      class="mdi-credit-card-2 ml-3"
                      style="height: 30px"
                    ></div>
                  </v-col>
                  <v-col
                    cols="auto"
                    md="4"
                    class="text-end px-0 mx-0 font-weight-bold"
                  >
                    <v-select
                      :items="computedPaymentMethods(tipo)"
                      label="Método de pago"
                      dense
                      solo
                      chips
                      x-small
                      class="small-select"
                      v-model="tipo.MetodoPago"
                      item-text="desc"
                      item-value="code"
                      @change="changeMethodPayment(tipo)"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          :input-value="data.selected"
                          color="primary"
                          dark
                          style="padding: 13px 15px !important"
                          small
                          close-icon
                        >
                          {{ data.item.desc }}
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-card-title>

              <!-- Contenido desplegable de cada tarjeta -->
              <v-card-text v-if="cardStates[index]" class="px-2">


                      <!--componente cheque-->

                      <form-cheque v-if="tipo.MetodoPago=== 'BANKNACION_CHEQUE'"
                      :tipo="tipo"
                      :carritoTiposElementos="carritoTiposElementos"

                      ></form-cheque>
                      <!--componente cheque-->



                      <div
                        v-for="(item, indexItem) in carritoOrdenado.filter(
                          (el) => el.TipoElementosId === tipo.TipoElementosId
                        )"
                        :key="item.ItemCode+'-'+item.db"
                        class="mt-5 mb-4 px-0 fondo-blanco"
                      >
                        <v-row
                          class="px-2 mx-0"
                          style="border-bottom: 1px solid #ddd"
                        >
                          <v-col
                            cols="auto"
                            style="width: 5%"
                            class="px-0 ml-0 py-0 mt-3"
                          >
                          <!--boton para desplegar aportes si corresponde-->
                            <v-btn
                              x-small
                              class="btn-panel-mas-menos"
                              @click="toggleCardItem(indexItem)"
                              style="background: #444; padding: 0px"
                                v-if="item.Aportes"
                            >
                              <v-icon
                                style="font-size: 0.8rem; margin: 0px; padding: 0px"
                              >
                                {{
                                  cardStatesItem[indexItem] ? "mdi-minus" : "mdi-plus"
                                }}
                              </v-icon>
                            </v-btn>
                             <!--boton para desplegar aportes si corresponde-->
                             <!--boton para desplegar dominio si corresponde-->
                            <v-btn
                              x-small
                              class="btn-panel-mas-menos"
                              @click="toggleCardItem(indexItem)"
                              style="background: #444; padding: 0px"
                                v-if="item.DefaultWarehouse=='CD-CHREP'"
                            >
                              <v-icon
                                style="font-size: 0.8rem; margin: 0px; padding: 0px"
                              >
                                {{
                                  cardStatesItem[indexItem] ? "mdi-minus" : "mdi-plus"
                                }}
                              </v-icon>
                            </v-btn>
                             <!--boton para desplegar dominio si corresponde-->
                          </v-col>
                          <v-col
                            cols="auto"
                            md="1"
                            class="px-2 mx-0 font-weight-bold"
                            >{{ item.ItemCode }}</v-col
                          >
                          <v-col cols="auto" style="width: 5%" class="px-0 mx-0">
                            <span
                              class="custom-badge"
                              :class="{
                                'badge-blue': item.db.substring(4, 6) === 'AC',
                                'badge-green': item.db.substring(4, 6) === 'MJ',
                                'badge-grey': item.db.substring(4, 6) === 'AN',
                              }"
                              >{{ item.db.substring(4, 6) }}</span
                            >
                          </v-col>
                          <v-col
                            cols="auto"
                            md="3"
                            class="px-0 mx-0 font-weight-bold text-left"
                            >{{ item.ForeignName | capitalizeFirst }}</v-col
                          >
                          <v-col
                            cols="auto"
                            md="2"
                            class="px-0 mx-0 font-weight-bold text-right"
                            >{{ "$ " + $formatMoney(item.priceList.Price) }}</v-col
                          >
                          <v-col
                            cols="auto"
                            md="1"
                            class="px-0 mx-0 font-weight-bold text-right"
                            >x{{ item.unidades }}</v-col
                          >
                          <v-col
                            cols="auto"
                            md="2"
                            class="px-0 mx-0 font-weight-bold info--text text-right"
                            >{{
                              "$ " +
                              $formatMoney(item.priceList.Price * item.unidades)
                            }}</v-col
                          >
                          <v-col
                            cols="auto"
                            md="1"
                            class="px-0 mx-0 font-weight-bold text-right"
                          >
                            <v-icon
                              v-if="item.ItemCode !== 'FLE' && item.ItemCode !== 'GB' && item.ItemCode !== 'GT'"
                              small
                              class="mr-2 my-0 py-0"
                              title="Eliminar"
                              @click="dialogEliminar(item.ItemCode)"
                              style="font-size: 20px; color: red"
                            >
                              mdi-delete
                            </v-icon>

                            <dialog-confirmar
                              v-model="dialogs[item.ItemCode]"
                              @input="dialog = $event"
                              :title="`¿Estás seguro de que deseas eliminar el producto <br>${capitalizeFirst(item.ForeignName)}?`"
                              info="Esta acción no se puede deshacer."
                              icon="mdi-alert-circle-outline"
                              color="#F44336"

                              @confirm="removeItem(item)"
                            />
                          </v-col>
                        </v-row>
                         <!--card para desplegar aportes si corresponde-->
                        <v-row
                          v-if="item.Aportes && cardStatesItem[indexItem]"
                          class="px-2 mx-0"
                          style="border-bottom: 1px solid #ddd"
                        >
                          <v-col
                            cols="auto"
                            style="width: 5%"
                            class="px-0 ml-0 py-0 mt-3"
                          >
                          </v-col>
                          <v-col
                            cols="auto"
                            md="1"
                            class="px-2 mx-0 font-weight-bold"
                          ></v-col>
                          <v-col cols="auto" style="width: 5%" class="px-0 mx-0">
                          </v-col>
                          <v-col
                            cols="auto"
                            md="3"
                            class="px-0 mx-0 font-weight-bold text-left"
                            >{{
                              item.Aportes.aporteItemName | capitalizeFirst
                            }}</v-col
                          >
                          <v-col
                            cols="auto"
                            md="2"
                            class="px-0 mx-0 font-weight-bold text-right"
                            >{{
                              "$ " + $formatMoney(item.Aportes.aporteItemPrice)
                            }}</v-col
                          >
                          <v-col
                            cols="auto"
                            md="1"
                            class="px-0 mx-0 font-weight-bold text-right"
                            >x{{ item.unidades }}</v-col
                          >
                          <v-col
                            cols="auto"
                            md="2"
                            class="px-0 mx-0 font-weight-bold info--text text-right"
                            >{{
                              "$ " +
                              $formatMoney(
                                item.Aportes.aporteItemPrice * item.unidades
                              )
                            }}</v-col
                          >
                        </v-row>
                        <!--card para desplegar aportes si corresponde-->

                         <!--card para desplegar dominio-->
                        <v-row
                          v-for="n in item.unidades" :key="n"
                          v-if="item.DefaultWarehouse=='CD-CHREP' && cardStatesItem[indexItem]"
                          class="px-2 mx-0 pb-0"
                          style="border-bottom: 1px solid #ddd"
                        >


                          <v-col
                            cols="auto"
                            md="5"
                            class="px-0 mx-1 font-weight-bold text-left pb-0"
                            >
                              <v-text-field   placeholder="Dominio"
                                v-model="item['dominio'+n]"
                                :rules="[
                                  $rulesRequerido,
                                  $rulesAlfaNum,
                                  $rulesMax500,
                                ]"
                                dense
                                outlined
                                @keyup="validateDominio(n, item)"
                                :error-messages="item['dominioErrors'+n]"
                              >

                              </v-text-field>
                            </v-col
                          >
                          <v-col
                            cols="auto"
                            md="5"
                            class="px-0 mx-1 font-weight-bold text-right  pb-0"
                            >
                              <v-text-field
                                    placeholder="Reingresar Dominio"
                                    v-model="item['dominioreingreso'+n]"
                                    :rules="[
                                      $rulesRequerido,
                                      $rulesAlfaNum,
                                      $rulesMax500,
                                    ]"
                                    dense
                                    outlined
                                    @keyup="validateReingresoDominio(n, item)"
                                    :error-messages="item['dominioreingresoErrors'+n]"
                              >
                              </v-text-field>

                            </v-col
                          >

                        </v-row>
                        <!--card para desplegar dominio-->
                      </div>
              </v-card-text>
            </v-card>
          </div>
          <div class="carrito__div pa-0 ma-0" style="height: 20vh">


          </div>
        </v-col>
        <v-col cols="auto" md="3" class="ma-0 pa-0">
          <v-row class="pt-0 my-0 ml-2" style="font-size: 13px; color: #444">
            <v-col cols="12" md="7" class="py-0 text-start">
              <span class="ml-0 text-h6" style="">Resumen</span></v-col
            >
          </v-row>

          <v-row v-if="$store.state.carrito.sumasPorTipo[1]" class="pt-4 my-0 ml-2" style="font-size: 13px; color: #444">
            <v-col cols="12" md="7" class="py-0 text-start"
              >Subtotal de elementos registrales:</v-col
            >

            <v-col cols="12" md="5" class="py-0 text-end pr-4 mr-0">
              ${{
                $store.state.carrito.sumasPorTipo[1]
                  ? $formatMoney($store.state.carrito.sumasPorTipo[1].subtotal)
                  : ""
              }}
            </v-col>
          </v-row>

          <v-row  v-if="$store.state.carrito.sumasPorTipo[2]" class="pt-4 my-0 ml-2" style="font-size: 13px; color: #444">
            <v-col cols="12" md="7" class="py-0 text-start"
              >Subtotal de elementos aduana:</v-col
            >

            <v-col cols="12" md="5" class="py-0 text-end pr-4 mr-0">
              ${{
                $store.state.carrito.sumasPorTipo[2]
                  ? $formatMoney($store.state.carrito.sumasPorTipo[2].subtotal)
                  : ""
              }}
            </v-col>
          </v-row>

          <v-row  v-if="$store.state.carrito.sumasPorTipo[3]" class="pt-4 my-0 ml-2" style="font-size: 13px; color: #444">
            <v-col cols="12" md="7" class="py-0 text-start"
              >Subtotal Adquirir saldo corriente:</v-col
            >

            <v-col cols="12" md="5" class="py-0 text-end pr-4 mr-0">
              ${{
                $store.state.carrito.sumasPorTipo[3]
                  ? $formatMoney($store.state.carrito.sumasPorTipo[3].subtotal)
                  : ""
              }}
            </v-col>
          </v-row>

          <v-row v-if="$store.state.carrito.gastosBancario>0" class="pt-4 my-0 ml-2" style="font-size: 13px; color: #444">
            <v-col cols="12" md="7" class="py-0 text-start"
              >Gastos Bancarios:</v-col
            >

            <v-col cols="12" md="5" class="py-0 text-end pr-4 mr-0">
              ${{
                $store.state.carrito.gastosBancario
                  ? $formatMoney(parseFloat($store.state.carrito.gastosBancario ))
                  : ""
              }}
            </v-col>
          </v-row>
           <v-row v-if="$store.state.carrito.gastosTransferencia>0" class="pt-4 my-0 ml-2" style="font-size: 13px; color: #444">
            <v-col cols="12" md="7" class="py-0 text-start"
              >Gastos Transferencia:</v-col
            >

            <v-col cols="12" md="5" class="py-0 text-end pr-4 mr-0">
              ${{
                $store.state.carrito.gastosTransferencia
                  ? $formatMoney(parseFloat($store.state.carrito.gastosTransferencia ))
                  : ""
              }}
            </v-col>
          </v-row>
          <v-row   v-if="!this.$store.state.carrito.cliente.CardCode" class="pt-4 my-0 ml-2" style="font-size: 13px; color: #444">
            <v-col  cols="12" md="7" class="py-0 text-start"
            >Envío por correo:
            </v-col
            >

            <v-col cols="12" md="5" class="py-0 text-end pr-4 mr-0">
              {{
                $store.state.carrito.gastosEnvio > 0
                  ? '$ '+$formatMoney($store.state.carrito.gastosEnvio)
                  : ""
              }}
            </v-col>
          </v-row>

          <hr  v-if="!this.$store.state.carrito.cliente.CardCode"
            style="border: none; height: 1px; background-color: #ccc"
            class="mx-1"
          />

          <v-row
            class="py-4 my-0 ml-2 py-2"
            style="font-size: 13px; color: #000; font-weight: 600"
          >
            <v-col cols="12" md="7" class="py-0 text-start"> Total</v-col>

            <v-col cols="12" md="5" class="py-0 text-end pr-4 mr-0">

              ${{
                $store.state.carrito.total
                  ? $formatMoney($store.state.carrito.total)
                  : ""
              }}
            </v-col>
          </v-row>

          <v-row class="py-1 my-0 mx-0">
            <v-col cols="12" md="12" class="mx-auto">
              <v-btn
                block
                color="btnPrimary"
                class="capitalize-first custom-btn"
                @click="dialogValidate"
              >
                Confirmar
              </v-btn>
              <dialog-validate
                v-model="dialogs['carritoValidateMetodosPago']"
                @input="dialog = $event"
                title="Método de pago requerido"
                info="Selecciona un método de pago para cada categoría antes de continuar con la compra."
                icon="mdi-alert-circle-outline"
                color="#ffc107"
              />
              <dialog-validate
                v-model="dialogs['validateCheque']"
                 @input="dialog = $event"
                title="Datos Incompleto"
                info="Completa los datos del cheque son requeridos para finalizar tu compra."
                icon="mdi-alert-circle-outline"
                color="#ffc107"
              />
              <dialog-validate
                v-model="dialogs['bancoNacionLimit']"
                @input="dialog = $event"
                title="Boleta Banco Nación no disponible"
                info="El método 'Boleta Banco Nación' no está disponible para montos superiores a $9.999.999. Por favor, elige otro método para continuar."
                icon="mdi-alert-circle-outline"
                color="#ffc107"
              />

              <dialog-form-validate
                v-model="dialogs['validateDominios']"
                @input="dialog = $event"
                :title=tituloValidacionDominio
                :bodyText=textoValidacionDominio
                icon="mdi-alert-circle-outline"
                color="#ffc107"
              />
              <dialog-reintentar
                v-model="dialogs['validateDominiosServicio']"
                @input="dialog = $event"
                 :title=tituloValidacionDominio
                :bodyText=textoValidacionDominio
                icon="mdi-close"
                textButton="Aceptar"
                color="#FF5252">
              </dialog-reintentar>


              <dialog-confirmar
                v-model="dialogs['carritoFacturas']"
                @input="dialog = $event"
                title="¿Estás seguro de que desea generar las facturas?"
                info="Esta acción no se puede deshacer."
                icon="mdi-alert-circle-outline"
                color="#0078D4"

                @confirm="viewFacturas()"
              />
              <v-btn
                block
                color="danger"
                class="mt-1 capitalize-first custom-btn"
                @click="dialogVaciar"
              >
                Vaciar Carrito
              </v-btn>


              <dialog-confirmar
                v-model="dialogs['carritoVaciar']"
                @input="dialog = $event"
                title="Vaciar carrito"
                info="¿Estás seguro de que quieres vaciar todos los articulos de tu carrito? Esta acción no se puede deshacer."
                icon="mdi-cart-outline"
                color="#F44336"

                @confirm="carritoVaciar()"
              />

              <dialog-validate
                v-model="dialogs['saveError']"
                title="Generación de Facturas"
                :info="'Lo sentimos, ocurrió un error. Vuelva a intentarlo más tarde o comuníquese con ACARA. '+messageError"
                icon="mdi-alert-circle-outline"
                color="#F44336"
                textButton="Aceptar"
              />

            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>

  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Swal from "sweetalert2";

import DialogValidate from "@/views/dialogs/Validate.vue";
import DialogConfirmar from "@/views/dialogs/Confirmar.vue";
import Loading from "@/views/components/Loading.vue";
import FormCheque from "@/views/carrito/FormCheque.vue";
import DialogFormValidate from "@/views/dialogs/FormValidate.vue";
import DialogReintentar from "@/views/dialogs/Reintentar.vue";


export default {
  components: {
    Loading,
    DialogValidate,
    DialogConfirmar,
    FormCheque,
    DialogFormValidate,
    DialogReintentar

  },

  data: (vm) => ({
    snackbar: false,
    text: "Registro Insertado",
    messageError: "",
    route: "aportes",
    routeFactura: "facturas",
    routeOrdenes: "ordenes",
    color: "success",
    timeout: 4000,
    isLoading: true,
    loadingMsg:"obteniendo datos...",
    carritoOrdenado: [],
    panelStates: [],
    panelStatesItem: [],
    bancoNacionLimit: false,
    dialogs: {
      carritoVaciar: false,
      carritoValidateMetodosPago: false,
      validateCheque: false,
      carritoFacturas: false,
      validateDominios:false,
      validateDominiosServicio:false,
      saveError:false,
    },
    cardStates: [],
    cardStatesItem: [],
    formatosRegex : {
        "AADDDAA": /^[A-Za-z]{2}\d{3}[A-Za-z]{2}$/,
        "ADDDAAA": /^[A-Za-z]\d{3}[A-Za-z]{3}$/,
        "AAADDD": /^[A-Za-z]{3}\d{3}$/,
        "AAADDD_rango": /^(?:([A-P][A-Z]{2}(?!000)\d{3})|([R-X][A-Z]{2}(?:00[1-9]|0[1-9][0-9]|1[0-9]{2}|2[0-8][0-9]|29[0-4])))$/,
        "AAADD": /^[A-Za-z]{3}\d{2}$/,
        "KKDDDAA": /^[Zz]{2}\d{3}[A-Za-z]{2}$/
    },
    tituloValidacionDominio : "",
    textoValidacionDominio :"",


  }),



  mounted() {
    this.loadData();
    //console.log(this.$store.state.user.sapProperties);
    console.log("Componente " + this.baseUrl + " creado");
  },

  computed: {
    ...mapState({
      carrito: (state) => state.carrito,
    }),
    paymentMethods() {
      return this.$store.getters.getPaymentMethods;
    },
    computedPaymentMethods() {

      return (tipo) => {

        return this.$store.getters.getPaymentMethods.filter((method) => {
          //compra elementos registrales
           if ((tipo.TipoElementosId === 1 ) && ( method.groupCode === "DEBITCARD" || method.groupCode === "CREDITCARD" || method.groupCode === "DEPOSIT"  || method.groupCode === "TRANSFER")) {
            return false;
          }

          //compra elementos aduana 2 y adquirir saldo cta cte = 3
          if ((tipo.TipoElementosId === 2 || tipo.TipoElementosId === 3) && (method.groupCode === 'SALDO' || method.groupCode === "DEBITCARD" || method.groupCode === "CREDITCARD" || method.groupCode === "DEPOSIT" || method.groupCode === "TRANSFER" )) {
            return false;
          }

          // Si es venta elementos aduana
          if ((tipo.TipoElementosId === 4 ) && ( method.groupCode === 'SALDO'||  method.groupCode === 'BTOB'  || method.groupCode === "DEBITCARD" || method.groupCode === "CREDITCARD" || method.groupCode === "DEPOSIT"  || method.groupCode === "TRANSFER" )) {
            return false;
          }

          return true;
        });
      };
    },

  },

  methods: {


    async fetchSaldoAFavor() {
      try {
        const cliente = this.$store.state.user.sapProperties.CardCode;
        const bases =  this.$db("ACARA")+','+this.$db("MJ");


        const saldos =  await this.$fetchSaldoAFavor('consultar', cliente, bases);

        if (saldos) {
          this.validarSaldoAFavor(saldos);
        } else {
          console.error('No se pudieron obtener los saldos.');
        }
      } catch (error) {
        console.error('Error al consultar los saldos:', error);
      }
    },

    validarSaldoAFavor(saldos) {


      const elementosRegistrales = this.carritoOrdenado.filter(
        (tipo) => tipo.TipoElementosId === 1
      );

      // Si no hay elementos registrales, no ejecutar el resto del método
      if (!elementosRegistrales) {
        return;
      }




      let DBACARA = this.$db("ACARA");
      let DBMJ = this.$db("MJ");

      const saldoACARA = Math.abs(parseFloat(saldos[DBACARA]?.CurrentAccountBalance || 0));
      const saldoMJ = Math.abs(parseFloat(saldos[DBMJ]?.CurrentAccountBalance || 0));

      const subtotalMJ = elementosRegistrales
        .filter((item) => item.db === this.$db("MJ"))
        .reduce((acc, item) => acc + item.priceList.Price * item.unidades, 0);

      const subtotalACARA = elementosRegistrales
        .filter((item) => item.db === this.$db("ACARA"))
        .reduce((acc, item) => acc + item.priceList.Price * item.unidades, 0);

      const gastoEnvioMJ = elementosRegistrales
        .filter((item) => item.db === this.$db("MJ") && item.ItemCode === "FLE")
        .reduce((acc, item) => acc + item.priceList.Price * item.unidades, 0);

      const esValidoMJ = subtotalMJ <= saldoMJ;
      const esValidoACARA = (subtotalACARA + gastoEnvioMJ) <= saldoACARA;

      if(this.paymentMethods.length){
      // Actualizar el estado en base a validaciones
        this.paymentMethods.forEach((tipo) => {
          if (tipo.code == 'SALDO') {
            tipo.disabled = !(esValidoACARA && esValidoMJ);
          }
        });
      }

    },

    async calcularCostoFlete() {
      // Eliminar cualquier gasto de envío existente antes de recalcular

      let esConcesionario = this.$store.state.user.sapProperties.Properties1;
      let esCamara        = this.$store.state.user.sapProperties.Properties5;
      let esVerifPolicial = this.$store.state.user.sapProperties.Properties8;
      let esRegistro      = this.$store.state.user.sapProperties.Properties11;
      let esDelegAduana   = this.$store.state.user.sapProperties.Properties16;
      let esAnmac         = this.$store.state.user.sapProperties.Properties23;



      //console.log(esConcesionario,esCamara,esVerifPolicial,esRegistro , esDelegAduana , esAnmac);

       //no pagan fletes
      if(esRegistro || esDelegAduana || esAnmac || (!esConcesionario && !esCamara && !esVerifPolicial))
      {
        this.$store.state.carrito.data = this.carritoOrdenado;
        this.$store.commit("carrito/calcularTotales");
        return;
      }

      this.carritoOrdenado = this.carritoOrdenado.filter((item) => item.ItemCode !== "FLE");

      const articulosConFlete = this.carrito.data.filter((item) => item.U_Flete === "tYES" && item.InventoryItem === true);



      // Si no hay artículos que paguen flete, salir de la función
      if (articulosConFlete.length === 0) {
        this.$store.state.carrito.data = this.carritoOrdenado;
        this.$store.commit("carrito/calcularTotales");
        return;
      }

      const articulosPorAlmacen = articulosConFlete.reduce((acc, item) => {
        const almacen = item.DefaultWarehouse;
        if (!acc[almacen]) {
          acc[almacen] = [];
        }
        acc[almacen].push(item);
        return acc;
      }, {});

      let cantidadEnviosTotal = 0;
      const precioUnitarioEnvio = this.$store.getters.getPrecioFlete;

      // Calcular la cantidad de envíos necesarios para cada almacén
      for (const warehouseCode in articulosPorAlmacen) {
        try {
          const response = await this.$axiosApi.getById(
            "warehousesByCode",
            `${warehouseCode}/`+this.$db("ACARA")
          );

          const U_UnidFleteC = response.data?.data?.U_UnidFleteC;
          if (!U_UnidFleteC) continue;

          const cantidadArticulos = articulosPorAlmacen[warehouseCode].reduce(
            (acc, item) => acc + item.unidades,
            0
          );

          cantidadEnviosTotal += Math.ceil(cantidadArticulos / U_UnidFleteC);

        } catch (error) {
          console.error(`Error al obtener U_UnidFleteC para ${warehouseCode}:`, error);
        }
      }

      const gastoFlete = {
        TipoElementosDesc: "Elementos Registrales",
        TipoElementosId: 1,
        ItemCode: "FLE",
        ItemName: "ENVIO X CORREO",
        ForeignName: "ENVIO X CORREO",
        priceList: { Price: precioUnitarioEnvio },
        unidades: cantidadEnviosTotal,
        db: this.$db("ACARA"),
        error: ""
      };

                                               //gasto_transferenciaACARA += (item.priceList.Price * item.unidades * this.$store.state.config.gasto_transferencia);


      this.carritoOrdenado.push(gastoFlete);

      // Actualizar el estado del carrito y recalcular totales
      this.$store.state.carrito.data = this.carritoOrdenado;
      this.$store.commit("carrito/calcularTotales");
    },

    async fetchAportesData() {
      try {
        let userState =
          this.$store.state.user.sapProperties.BPAddresses[1].State;
        let userPriceList = this.$store.state.user.sapProperties.PriceListNum;
        let userPartner = this.$store.state.user.sapProperties.Properties40;
        let userAdefa = this.$store.state.user.sapProperties.Properties41;
        let items = this.carrito.data.map((item) => item.ItemCode);


        let formSap = {
          items: {
            userState: userState, //reemplazar con los valores reales del user
            userPriceList: userPriceList,
            userPartner: userPartner, //reemplazar con los valores reales del user
            userAdefa: userAdefa, //reemplazar con los valores reales del user
            itemsID: items,
          },
        };

        const response = await this.$axiosApi.post(this.route, formSap);

        if (response.status != 200) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.data.data;

        return data;
      } catch (error) {
        console.error("Error en la conexión con la API:", error);
      }
    },
    async loadData() {
      this.isLoading = true;
      //aca tengo q llamar al endpoint para buscar la info d los aportes


      // Llamamos al método fetchUserData y esperamos la respuesta
      const itemsAportes = await this.fetchAportesData();

      let dataCarrito = this.carrito.data;

      if (itemsAportes) {
        itemsAportes.forEach((element) => {
          //console.log(element);
          const indice = dataCarrito.findIndex(
            (carrito) => carrito.ItemCode === element.U_ArtOrigen
          );
          //creo un nuevo indice en el objeto para setear los aportes
          dataCarrito[indice].Aportes = element;
        });
        this.carrito.data = dataCarrito;

      }

      setTimeout(() => {


        this.carritoOrdenado = this.carrito.data
          .slice()
          .sort((a, b) => a.TipoElementosId - b.TipoElementosId);

        this.carritoTiposElementos = this.obtenerTiposElementos();

        this.mergedTiposElementosConStore(),

        //recorrer tipos de elementos si tienen metodopago seteado calcular segun metodo

        this.carritoTiposElementos.forEach((e) => {
            if (e.MetodoPago) {
              this.changeMethodPayment(e);
            }
        });

        this.isLoading = false;

        this.panelStates = this.carritoTiposElementos.map(() => false);

        this.calcularCostoFlete();

        this.$store.commit("carrito/calcularTotales");

        this.fetchSaldoAFavor();
      }, 400);
    },
    obtenerTiposElementos(){
      // Primero mapeamos el carrito para obtener solo TipoElementosId y TipoElementosDesc
        let tipoElementosArray = this.carritoOrdenado.map((item) => ({
          TipoElementosId: item.TipoElementosId,
          TipoElementosDesc: item.TipoElementosDesc,
          MetodoPago: "",
          Cheques:
            {acara:
              {
              banco:"",
              importe:"",
              vtoCheque:"",
              nroCheque:"",
              },


              mj:
              {
                banco:"",
                importe:"",
                vtoCheque:"",
                nroCheque:"",
              }

            }


        }));

        // Luego, eliminamos duplicados usando `filter` y `findIndex`

        return tipoElementosArray.filter( (item, index, self) => index === self.findIndex(
              (t) =>
                t.TipoElementosId === item.TipoElementosId &&
                t.TipoElementosDesc === item.TipoElementosDesc
            )
        );

    },
    mergedTiposElementosConStore(){



        const merged = this.carritoTiposElementos.map(current => {
            // Buscar si existe en el store
            const existing = this.$store.state.carrito.tipos.find(
                item => item.TipoElementosId === current.TipoElementosId
            );
            // Si existe y tiene MetodoPago, asignarlo al elemento actual
            if (existing && existing.MetodoPago) {
                return {
                    ...current,
                    MetodoPago: existing.MetodoPago,
                };
            }
            // Si no, devolver el elemento actual tal cual
            return current;
        });

        this.$store.state.carrito.tipos = merged;
        this.carritoTiposElementos = merged;


    },

    dialogEliminar(itemId) {
      this.$set(this.dialogs, itemId, true);
    },
    dialogVaciar() {
      this.dialogs.carritoVaciar = true;
    },

    async dialogValidate() {

      this.isLoading = true;
      this.loadingMsg = "Realizando validaciones...";


      // Bandera para verificar si todos los métodos de pago están seleccionados
      let allMethodsSelected = true;
      //let hasChequeSelected = false;
      let validacionCheque =true;
      // Recorre los tipos de elementos en el carrito y verifica sus métodos de pago

      this.carritoTiposElementos.forEach((tipo) => {

        if (!tipo.MetodoPago) {
          // Si algún método de pago no está seleccionado, marca la bandera y muestra el diálogo de validación
          allMethodsSelected = false;
        } else if (tipo.MetodoPago === "BANKNACION_CHEQUE") {

          //si el total de acara >0
          //entonces pregunto si el banco e importe distinto de null
          if(this.$store.state.carrito.sumasPorTipo[tipo.TipoElementosId].subtotalDb[this.$db("ACARA")] >0)
          {
            if(tipo.Cheques.acara.banco =='' || tipo.Cheques.acara.importe == '' || (tipo.Cheques.acara.importe ==0)
          || (tipo.Cheques.acara.vtoCheque =='') || (tipo.Cheques.acara.nroCheque ==''))
            {
              validacionCheque =false;
            }
          }


          if(this.$store.state.carrito.sumasPorTipo[tipo.TipoElementosId].subtotalDb[this.$db("MJ")] >0)
          {
            if(tipo.Cheques.mj.banco =='' || tipo.Cheques.mj.importe == '' || (tipo.Cheques.mj.importe ==0)
            || (tipo.Cheques.mj.vtoCheque =='') || (tipo.Cheques.mj.nroCheque =='')
          )
            {
              validacionCheque =false;
            }
          }



        }
      });

      //-------si es un registro => validacion para ver si hay elementos con dominios y estan todos ok


      let validacionDominios =true;
      let validacionDominiosServicio =true;

      if(this.$store.state.user.sapProperties.Properties11)
      {
          let dominiosIngresados = [];


            for (const item of this.carritoOrdenado.filter((el) => el.DefaultWarehouse === "CD-CHREP")) {

                      console.log("item.dominio1")
                      console.log(item)
                      for(let i=1; i <= item.unidades; i++)
                      {
                        //genero un array con los dominio ingresados para saber si hay repetidos
                        if(dominiosIngresados.includes(item["dominio"+i]))
                        {
                          //dominio repetido
                          this.tituloValidacionDominio = "Dominios repetidos";
                          this.textoValidacionDominio = "El dominio <b>"+item["dominio"+i]+"</b> se encuentra repetido";
                          validacionDominios = false;
                        }
                        else
                        {
                          dominiosIngresados.push(item["dominio"+i]);
                        }


                        if((item["dominioErrors"+i] || item["dominioreingresoErrors"+i] || typeof item["dominio"+i] == "undefined") || typeof item["dominioreingreso"+i] == "undefined")
                        {
                          validacionDominios = false;
                          //si quiero identificar el item que no se cargo el dominio
                          //this.itemConDominioNoValido = item.ItemCode;
                          this.tituloValidacionDominio = "Campos Obligatorios";
                          this.textoValidacionDominio = "Debe completar el campo de “Dominio” del item <b>"+item.ItemCode+"</b> para continuar con la compra.";
                        }

                      }


            }
            //le pego al endpoint
            //x cada item le valido cantidad ingresada y dominio corresponde segun endpoint?


            if(validacionDominios==true && dominiosIngresados.length >0)
            {
                const reposicionDominios = await this.reposicionchapasdominio(dominiosIngresados);

                for (const item of this.carritoOrdenado.filter((el) => el.DefaultWarehouse === "CD-CHREP")) {

                    for(let i=1; i <= item.unidades; i++)
                    {
                        if(item.ItemCode != 'MCHT' && item.ItemCode != 'CHTA') //si no son trailers
                        {
                            validacionDominiosServicio = await this.validarOrdenChapa(reposicionDominios,item["dominio"+i],item.U_OrdenChapas);

                            if(!validacionDominiosServicio)
                            break;
                        }
                    }
                }
            }


      }
      //-----------fin validacion para ver si hay elementos con dominios y estan todos ok

      // Si no todos los métodos de pago están seleccionados, muestra el diálogo de validación

      this.isLoading = false;
      if (!allMethodsSelected) {
        this.dialogs.carritoValidateMetodosPago = true;
      }  else if(!validacionCheque){
        this.dialogs.validateCheque = true;
      } else if(!validacionDominios){
        this.dialogs.validateDominios= true;
      }else if(!validacionDominiosServicio){
        this.dialogs.validateDominiosServicio= true;
      }else {

        this.dialogs.carritoFacturas = true;
      }

    },


    async reposicionchapasdominio(dominiosIngresados) {
       try {
        let filter = dominiosIngresados.map(itemIng => `U_DistNumber eq '${itemIng}'`).join(' or ');
        let params = 'filter=$filter=(' + filter + ')';

        let url = this.$axiosApi.routes["reposicionchapasdominio"];
        let fullUrl = `${url}?${params}`;

        let r =  await this.$axiosApi.sendRequest(fullUrl,'GET');

        return r.data.data;
      } catch (error) {
        this.$handleAxiosError(error);
        return false;
      }
    },

    async validarOrdenChapa(reposicionDominios,dominio,copiaPedida) {
       try {
         let validacion = true;

              this.tituloValidacionDominio = "Error en la operación";

              if (reposicionDominios[dominio] !== undefined) {


                let line = reposicionDominios[dominio];

                //if (r.data.data[DistNumber].hasDomainRep) {
                  console.info(`La chapa ${dominio} tiene repo, se pide: ${copiaPedida} en sistema: ${line.U_OrdenChapas} Próxima: ${line.U_OrdenChapas + 1} Estado: ${line.U_Estado}`);
                  let ordenChapaCompra = Number(copiaPedida);
                  let nextOrdenChapaValid = line.U_OrdenChapas + 1;


                  switch (line.U_Estado) {
                    case 'L':
                      if (Number(line.U_OrdenChapas) !== ordenChapaCompra) {
                        this.textoValidacionDominio =  this.handleError(dominio, line.U_OrdenChapas);
                        return false;
                      }
                      break;
                    case 'V':
                      if (nextOrdenChapaValid !== ordenChapaCompra) {
                        this.textoValidacionDominio =  this.handleError(dominio, nextOrdenChapaValid);
                        return false;
                      }
                      break;
                    case 'A':
                      if (line.U_OrdenChapas === 0) {
                        this.textoValidacionDominio =  'La chapa '+dominio+ ' para 0km está anulada';
                        return false;
                      } else if (nextOrdenChapaValid !== ordenChapaCompra) {
                        this.textoValidacionDominio =  'Para la chapa ' + dominio + ' corresponde pedir la ' + transformToText(nextOrdenChapaValid)

                        return false;
                      }
                      break;
                    case 'R':
                      const reservationDays = 6;
                      if (new Date(line.U_Fecha) >= new Date().setDate(new Date().getDate() - reservationDays)) {
                        this.textoValidacionDominio =    'La chapa '+dominio+ ' esta reservada desde el día '+line.U_Fecha+' por el registro '+line.U_CardCode;
                        return false;
                      } else if (line.U_OrdenChapas !== ordenChapaCompra) {
                        this.textoValidacionDominio =  this.handleError(dominio, line.U_OrdenChapas);
                        return false;
                      }
                      break;
                  }
                //} else {
                  console.info(`La chapa ${dominio} ¡NO! tiene repo, se pide: ${copiaPedida} en sistema: ${line.U_OrdenChapas} Próxima: ${line.U_OrdenChapas + 1} Estado: ${line.U_Estado}`);
                  if (['A', 'L'].includes(line.U_Estado)) {
                    this.textoValidacionDominio =  this.handleError(dominio, line.U_Estado === 'A' ? 'anulada' : 'liberada', true);
                    return false;
                  }
                //}
              } else {
                console.info(`La chapa ${dominio} no está en la tabla Repo Chapas, sólo permite duplicados`);
                if (copiaPedida * 1 !== 2) {
                  this.textoValidacionDominio =  this.handleError(dominio, 2);
                  return false;
                }
              }




        return true;
      } catch (error) {
        this.$handleAxiosError(error);
        return false;
      }
    },

    handleError(DistNumber, expectedOrder, critical = false) {
      let m  =`Para la chapa ${DistNumber} corresponde pedir la ${this.$transformToText(expectedOrder)}`;
      console.log(m);
      return  m;

    },

    isDelegation(userPorperties) {
      return (
        userPorperties.Properties16 === true ||
        userPorperties.Properties21 === true ||
        userPorperties.Properties16 === 'tYES' ||
        userPorperties.Properties21 === 'tYES'
      );
    },
    async isVentaAduana(array) {
      return array.some(item => item.TipoElementosId === 4);
    },
    async storeFactura() {
      try {
        let formUserData = {};
        let url = '';
        let isVenta = await (this.isVentaAduana(this.carritoTiposElementos));
        if(isVenta == true){
          url = this.routeOrdenes;
          formUserData = {
            cardCode: this.$store.state.carrito.cliente.CardCode,
            //delegation: this.isDelegation(this.$store.state.carrito.cliente),
            delegation: this.$store.state.user.sapProperties.U_Deleg && this.$store.state.user.sapProperties.U_Deleg!='null'?this.$store.state.user.sapProperties.U_Deleg:this.$store.state.user.sapProperties.CardCode,
            uNamePortal: this.$store.state.user.sapProperties.CardCode,
            namePortal: this.$store.state.user.sapProperties.CardName,
          };
        }
        else{
          url = this.routeFactura;
          formUserData = {
            cardCode: this.$store.state.user.sapProperties.CardCode,
            delegation: this.isDelegation(this.$store.state.user.sapProperties),
            uNamePortal: this.$store.state.user.sapProperties.CardCode,
            namePortal: this.$store.state.user.sapProperties.CardName,
          };
        }

        let itemTemp = [];

        let itemsAregistar =[];

         for (let item of this.carrito.data) {

              if(item.DefaultWarehouse === "CD-CHREP")
              {

                for(let i=1; i <= item.unidades; i++)
                {
                  itemTemp = { ...item };
                  itemTemp.U_SEIDORAR_DistNuD = item["dominio"+i];
                  //itemTemp.U_DistNumberD = item["dominio"+i];
                  //temTemp.U_DistNumberH = item["dominio"+i];
                  itemTemp.U_SEIDORAR_DistNuH = item["dominio"+i];
                  //itemTemp.U_DistNumber = item["dominio"+i];
                  itemTemp.unidades = 1;

                  itemsAregistar.push(itemTemp);
                  itemTemp = [];
                }
              }
              else
              {
                itemsAregistar.push(item);
              }
        }

        let form = {
          userData: formUserData,
          elementsTypes: this.carritoTiposElementos,
         // items: this.carrito.data,
         items : itemsAregistar,
        };


        console.log(form)

        const response = await this.$axiosApi.post(url, form);

        if (response.status != 200) {
          throw new Error(`Error: ${response.status}`);
        }
        const data = await response;

        return data;
      } catch (error) {
        return error;
        console.error("Error en la conexión con la API:", error);
      }
    },

    async viewFacturas() {
      this.isLoading = true;
      this.loadingMsg = "Generando Facturas...";
      //console.log(this.$store.state.carrito.cliente)

      // Llamamos al método storeFactura y esperamos la respuesta
      const responseFactura = await this.storeFactura();

      if (responseFactura.status == 200) {
        let dataFacturas = responseFactura.data.data;
        console.log("dataFacturas");
        console.log(dataFacturas);
        this.$router.push({
          name: "carrito_detalle_facturacion",
          params: { facturas: dataFacturas },
        });
        this.$store.commit("carrito/remove");
        this.$store.commit("carrito/removeCliente");
      }
      else
      {
        this.isLoading = false;
        this.dialogs.carritoFacturas = false;
        ;
        this.dialogs.saveError = true;
        this.messageError = (responseFactura.response.data.message);

        /*let message=  responseFactura.messages ?? "";
        Swal.fire({
            icon: "error",
            title: "No se generaron los pedidos correspondientes",
            html: message,
        });*/

      }

    },

    carritoVaciar() {
      this.$store.commit("carrito/remove");
      this.dialogs.carritoVaciar = false;
      this.loadData();
      this.$forceUpdate();
    },

     removeItem(item) {
      this.eliminarGastos("GT",item.TipoElementosId);

      this.eliminarGastos("GB",item.TipoElementosId);
      this.eliminarGastos("FLE",item.TipoElementosId);
      this.$store.commit("carrito/removeItem", item);
      this.$delete(this.dialogs, item.ItemCode);
      //this.changeMethodPayment(item.tipoElementoId);



      this.loadData();

    },
    togglePanel(index) {
      this.$set(this.panelStates, index, !this.panelStates[index]);
    },

    togglePanelItem(index) {
      this.$set(this.panelStatesItem, index, !this.panelStatesItem[index]);
    },

    toggleCard(index) {
      // Alterna la expansión de la tarjeta
      this.$set(this.cardStates, index, !this.cardStates[index]);
    },
    toggleCardItem(indexItem) {
      // Alterna la expansión del ítem dentro de la tarjeta
      this.$set(
        this.cardStatesItem,
        indexItem,
        !this.cardStatesItem[indexItem]
      );
    },

    changeMethodPayment(tipo){

        this.isLoading = true;

        setTimeout(() => {
                //saldo
                this.eliminarGastos("GT",tipo.TipoElementosId);
                this.eliminarGastos("GB",tipo.TipoElementosId);


                let gasto_bancarioACARA =0;
                let gasto_bancarioMJ =0;
                let gasto_transferenciaACARA =0;
                let gasto_transferenciaMJ =0;

                this.cardStates[tipo.TipoElementosId-1] = true;//despliega el card despues de seleccionar el metodo de pago

                if(tipo.TipoElementosId==1 || tipo.TipoElementosId==3)
                {


                      if((tipo.MetodoPago == 'BTOB'))
                      {

                        this.carritoOrdenado.filter( (el) => el.TipoElementosId === tipo.TipoElementosId )
                                              .forEach(item => {

                              if(item.db ==this.$db("ACARA"))
                              {
                                  //elem registrales o aduana
                                  if (item.InventoryItem ) {
                                      gasto_transferenciaACARA += (item.priceList.Price * item.unidades * this.$store.state.config.gasto_transferencia);

                                  }

                                  //adquirir saldo ACARA
                                  if (item.ItemCode =="PA") {
                                      gasto_transferenciaACARA += (item.priceList.Price * item.unidades * this.$store.state.config.gasto_transferencia);

                                  }
                              }
                              if(item.db ==this.$db("MJ"))
                              {


                                  //elem registrales o aduana
                                  if (item.InventoryItem   ) {
                                      gasto_transferenciaMJ += (item.priceList.Price * item.unidades * this.$store.state.config.gasto_transferencia);

                                      if(item.Aportes)
                                      {
                                        gasto_transferenciaACARA += item.Aportes.aporteItemPrice * item.unidades  * this.$store.state.config.gasto_transferencia;

                                      }
                                      else
                                      {
                                        if(item.U_FLete =="tYes")
                                        {

                                          // si paga flete se le agrega el gasto de transferencia ACARA por el flete
                                          gasto_transferenciaACARA += (item.priceList.Price * item.unidades * this.$store.state.config.gasto_transferencia);
                                          // si mj paga flete --> agregar el gasto de transferencia de acara
                                          }
                                      }
                                  }
                                  //adquirir saldo MJ
                                  if (item.ItemCode =="P2"  ) {
                                      gasto_transferenciaMJ += (item.priceList.Price * item.unidades * this.$store.state.config.gasto_transferencia);

                                  }
                              }


                        });

                        let minGastoTransferencia = this.$store.state.config.gasto_transferencia_min;

                        //cambie el if a q el gasto_transferencaACARA sea >= 0
                        if(gasto_transferenciaACARA < minGastoTransferencia && gasto_transferenciaACARA >0)
                        {
                          gasto_transferenciaACARA =minGastoTransferencia;
                        }
                        //cambie el if a q el gasto_transferenciaMJ sea >= 0
                        if(gasto_transferenciaMJ < minGastoTransferencia && gasto_transferenciaMJ >0)
                        {
                          gasto_transferenciaMJ =minGastoTransferencia;
                        }


                          if (gasto_transferenciaACARA  >0) {

                            // array minimo de gb hay que corroborar si es suficiente para
                            // la factura, sino habra q hacer un getItem GT


                              let gtACARA={
                                "TipoElementosDesc": tipo.TipoElementosDesc,
                                "TipoElementosId": tipo.TipoElementosId,
                                "ItemCode": "GT",
                                "ItemName": "Gastos Transferencia",
                                "ForeignName": "Gastos Transferencia",
                                "priceList":{"Price":gasto_transferenciaACARA},
                                "unidades":1,
                                "db":this.$db("ACARA"),
                                "error":""
                              }


                              this.carritoOrdenado.push(gtACARA);
                          }

                          if (gasto_transferenciaMJ  >0) {
                            let gtMJ={
                                "TipoElementosDesc": tipo.TipoElementosDesc,
                                "TipoElementosId": tipo.TipoElementosId,
                                "ItemCode": "GT",
                                "ItemName": "Gastos Transferencia",
                                "ForeignName": "Gastos Transferencia",
                                "priceList":{"Price":gasto_transferenciaMJ},
                                "unidades":1,
                                "db":this.$db("MJ"),
                                "error":""
                              }
                              this.carritoOrdenado.push(gtMJ);
                          }





                      }
                      else if( (tipo.MetodoPago == 'BANKNACION_CHEQUE'|| tipo.MetodoPago =='BANKNACION_EFECTIVO'))
                      {

                        if(Number(this.$store.state.carrito.sumasPorTipo[tipo.TipoElementosId].subtotal) > 9999999) {
                          this.dialogs.bancoNacionLimit = true;
                          tipo.MetodoPago = '';
                          this.isLoading = false;
                          return;
                        }

                          let gasto_bancarioMinimo =   this.$store.state.config.gasto_bancario_min;;

                          this.carritoOrdenado.filter( (el) => el.TipoElementosId === tipo.TipoElementosId )
                                              .forEach(item => {
                              if(item.db ==this.$db("ACARA"))
                              {
                                gasto_bancarioACARA += (item.priceList.Price * item.unidades * this.$store.state.config.gasto_bancario);
                              }
                          });

                          this.carritoOrdenado.filter( (el) => el.TipoElementosId === tipo.TipoElementosId )
                                              .forEach(item => {
                              if(item.db ==this.$db("MJ"))
                              {
                                gasto_bancarioMJ += (item.priceList.Price * item.unidades * this.$store.state.config.gasto_bancario);
                                if(item.Aportes)
                                {

                                  gasto_bancarioACARA += item.Aportes.aporteItemPrice * item.unidades  * this.$store.state.config.gasto_bancario;

                                }
                              }
                          });



                          if(gasto_bancarioACARA < gasto_bancarioMinimo && gasto_bancarioACARA >0)
                          {
                            gasto_bancarioACARA =gasto_bancarioMinimo;
                          }
                          if(gasto_bancarioMJ < gasto_bancarioMinimo && gasto_bancarioMJ >0)
                          {
                            gasto_bancarioMJ =gasto_bancarioMinimo;
                          }

                          if (gasto_bancarioACARA  >0) {

                            // array minimo de gb hay que corroborar si es suficiente para
                            // la factura, sino habra q hacer un getItem GB

                            let gbAcara={
                                  "TipoElementosDesc": tipo.TipoElementosDesc,
                                  "TipoElementosId": tipo.TipoElementosId,
                                  "ItemCode": "GB",
                                  "ItemName": "Gastos Bancarios",
                                  "ForeignName": "Gastos Bancarios",
                                  "priceList":{"Price":gasto_bancarioACARA},
                                  "unidades":1,
                                  "db":this.$db("ACARA"),
                                  "error":""
                                }


                              this.carritoOrdenado.push(gbAcara);
                          }

                          if (gasto_bancarioMJ  >0) {
                              let gbMJ={
                                "TipoElementosDesc": tipo.TipoElementosDesc,
                                "TipoElementosId": tipo.TipoElementosId,
                                "ItemCode": "GB",
                                "ItemName": "Gastos Bancarios",
                                "ForeignName": "Gastos Bancarios",
                                "priceList":{"Price":gasto_bancarioMJ},
                                "unidades":1,
                                "db":this.$db("MJ"),
                                "error":""
                              }
                              this.carritoOrdenado.push(gbMJ);
                          }


                      }
                }
                else if (tipo.TipoElementosId === 2) {
                  if (tipo.MetodoPago === 'BANKNACION_CHEQUE' || tipo.MetodoPago === 'BANKNACION_EFECTIVO') {
                    if(Number(this.$store.state.carrito.sumasPorTipo[tipo.TipoElementosId].subtotal) > 9999999) {
                      this.dialogs.bancoNacionLimit = true;
                      tipo.MetodoPago = '';
                      this.isLoading = false;
                      return;
                    }
                    }
                  }
                else if (tipo.TipoElementosId === 4) {
                  if (tipo.MetodoPago === 'BANKNACION_CHEQUE' || tipo.MetodoPago === 'BANKNACION_EFECTIVO') {
                    if(Number(this.$store.state.carrito.sumasPorTipo[tipo.TipoElementosId].subtotal) > 9999999) {
                      this.dialogs.bancoNacionLimit = true;
                      tipo.MetodoPago = '';
                      this.isLoading = false;
                      return;
                    }
                    }
                  }

                  this.$store.state.carrito.tipos = this.carritoTiposElementos;

                  this.$store.state.carrito.data =this.carritoOrdenado;
                  this.$store.commit("carrito/calcularTotales");
                  this.$forceUpdate();

                  this.isLoading = false;
              },400);


    },

    eliminarGastos(tipoGasto,tipoElementoId){
      let existingItemIndex = this.carritoOrdenado.findIndex(el => el.ItemCode === tipoGasto && el.db ===this.$db("ACARA") && el.TipoElementosId ===tipoElementoId);

      if (existingItemIndex !== -1) {
           this.carritoOrdenado.splice(existingItemIndex, 1);
      }
        let existingItemIndexMJ = this.carritoOrdenado.findIndex(el => el.ItemCode === tipoGasto && el.db ===this.$db("MJ") && el.TipoElementosId ===tipoElementoId);

      if (existingItemIndexMJ !== -1) {

          this.carritoOrdenado.splice(existingItemIndexMJ, 1);
      }

    },

      capitalizeFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
    verProductos(){

         if (this.$canSap("venderElementosAduana")) {
          this.$router.push({ name: 'elementos_aduana_venta' })
        } else {
          this.$router.push({ name: 'elementos_registrales' })
        }



    },

    validateDominio(index, item) {

      item["dominio"+index] =  item["dominio" + index] && item["dominio" + index].trim() !== '' ? item["dominio"+index].toUpperCase():item["dominio"+index];
      let code = item.ItemCode;
      let formato = item.U_Numeracion;
      const regex = new RegExp(this.formatosRegex[formato]);

      if (!regex.test(item["dominio"+index])) {
        item["dominioErrors"+index] = 'Debe ser un dominio adecuado al formato';

        //item["dominioErrors"+index] =   `El formato debe ser ${formato}`;
      } else if(formato === "AAADDD")
      {
          let regex2 = new RegExp(this.formatosRegex[formato+"_rango"]);

          if (!regex2.test(item["dominio"+index])) {
            item["dominioErrors"+index] = 'La chapa '+item["dominio"+index]+' no se encuentra en un rango de chapas originales asignado';

           }
           else{
              item["dominioErrors"+index] ='';
            }

      }
      else{
         item["dominioErrors"+index] ='';
      }
      item["dominioreingreso"+index] =  item["dominioreingreso" + index] && item["dominioreingreso" + index].trim() !== '' ? item["dominioreingreso"+index].toUpperCase(): item["dominioreingreso"+index];
      if (item["dominio"+index] !== item["dominioreingreso"+index]) {

        item["dominioreingresoErrors"+index] =  'Los dominios no coinciden';
      }
       this.$forceUpdate();

    },
    validateReingresoDominio(index, item) {
        item["dominioreingreso"+index] =  item["dominioreingreso" + index] && item["dominioreingreso" + index].trim() !== '' ? item["dominioreingreso"+index].toUpperCase(): item["dominioreingreso"+index];

        let code = item.ItemCode;
        let formato = item.U_Numeracion;
        let regex = new RegExp(this.formatosRegex[formato]); // Utiliza el regex correspondiente

        if (item["dominio"+index] !== item["dominioreingreso"+index]) {

          item["dominioreingresoErrors"+index] =  'Los dominios no coinciden';
        } else if (!regex.test(item["dominioreingreso"+index])) {
          //item["dominioreingresoErrors"+index] = `El formato debe ser ${formato}`;
          item["dominioreingresoErrors"+index] = 'Debe ser un dominio adecuado al formato';
        } else if(formato === "AAADDD")
      {
          let regex2 = new RegExp(this.formatosRegex[formato+"_rango"]);

          if (!regex2.test(item["dominioreingreso"+index])) {
            item["dominioreingresoErrors"+index] = 'La chapa '+item["dominioreingreso"+index]+' no se encuentra en un rango de chapas originales asignado';

           }
           else{
              item["dominioreingresoErrors"+index] ='';
            }

      }
      else{
         item["dominioreingresoErrors"+index] ='';
      }
        this.$forceUpdate();
    }



  },
  watch: {},
};
</script>

<style>
.custom-btn {
  text-transform: none;
  font-family: inherit;
}

.capitalize-first::first-letter {
  text-transform: capitalize;
}

.small-select .v-input__control {
  min-height: 18px !important; /* Altura total del select */
}

.small-select .v-label {
  font-size: 14px !important; /* Tamaño de la fuente del label */

  line-height: 1;
}

.fondo-blanco {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
}

.v-list-item--disabled {
  color: gray !important;
  cursor: not-allowed !important;
  opacity: 0.6 !important;
}
</style>

